import React, { useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

function BasicExample(dict) {
  const items = [];

  for (const [index, d] of dict.dict.entries()) {
    items.push(
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{d.cout_annee.toFixed(2)}</td>
        <td>{d.cout_cumule.toFixed(2)}</td>
        <td>{d.par_annee.toFixed(2)}</td>
        <td>{d.reste.toFixed(2)}</td>
      </tr>
    );
  }

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Année</th>
          <th>Remboursement Emprunt Année</th>
          <th>Remboursement Emprunt Cumulé</th>
          <th>Remboursement Crédit Année</th>
          <th>Reste à payer</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

function calcul_cout(capital, taux, years) {
  var dict = [];
  if (!(isNaN(capital) && isNaN(taux) && isNaN(years))) {
    capital = parseFloat(capital);
    taux = parseFloat(taux) / 100;
    years = parseFloat(years);
    let reste = capital;
    let par_annee = capital / years;
    let cout = 0;
    for (let i = 0; i < years; i++) {
      cout += reste * taux;
      dict.push({
        annee: i,
        cout_annee: reste * taux,
        cout_cumule: cout,
        reste: reste,
        par_annee: par_annee,
      });
      reste -= par_annee;
    }
    return dict;
  } else {
    return 0;
  }
}

function numberWithSpaces(x) {
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function NameForm() {
  const [capital, setCapital] = useState(100000);
  const [years, setYears] = useState(20);
  const [taux, setTaux] = useState(1.5);

  return (
    <div>
      <Container>
        <h1>Simulateur de Crédit</h1>
        <Row>
          <Col>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Capital emprunté</Form.Label>
                <Form.Control
                  type="number"
                  step="1000"
                  value={capital}
                  onChange={(e) => setCapital(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Taux</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="1"
                  value={taux}
                  onChange={(e) => setTaux(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Année</Form.Label>
                <Form.Control
                  type="number"
                  value={years}
                  onChange={(e) => setYears(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Body id="result">
                Capital emprunté : {capital}
                <br></br>
                Taux: {taux}
                <br></br>
                Année: {years}
                <br></br>
                Cout:
                {numberWithSpaces(
                  calcul_cout(capital, taux, years).at(-1).cout_cumule
                )}
                <br></br>
                Cout Total:
                {numberWithSpaces(
                  calcul_cout(capital, taux, years).at(-1).cout_cumule +
                    parseInt(capital)
                )}
              </Card.Body>
              <Button
                variant="primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    document.getElementById("result").innerText
                  );
                }}
              >
                Copy
              </Button>
            </Card>
          </Col>
        </Row>
        <BasicExample dict={calcul_cout(capital, taux, years)} />
      </Container>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<NameForm />);
